<template>
  <div class="Collating">
  
    <MyMenu :menuname="'other'"></MyMenu>
    <!-- 头部 -->
    <Stepbar  :active="2"></Stepbar>
    <!-- 头部END -->

   
    <!-- 主要内容容器 -->
    <div class="content">
      <materials v-if="isEnterpriseOrder" :enterpriseId="enterpriseId" :source="'placeOrder'"></materials>
      <personal v-else :source="'placeOrder'"></personal>
    </div>
    <!-- 主要内容容器END -->
  </div>
</template>
<script>
import Stepbar from './item/Stepbar.vue'
import materials from './item/materials.vue'
import personal from './item/personal.vue'

export default {
  name: 'Collating',
  components: { Stepbar,materials,personal},
  data() {
    return {
      isEnterpriseOrder:false,
      enterpriseId:''
    }
  },
  created() {
    if (this.$route.query.enterpriseId != undefined) {
      this.enterpriseId = this.$route.query.enterpriseId
    }
    var currentCommodityDetail = JSON.parse(localStorage.getItem('currentCommodityDetail'))
    if(currentCommodityDetail){
      this.isEnterpriseOrder =  currentCommodityDetail.isEnterpriseOrder
    }
  },
  computed: {
    
    
  },

  methods: {
    
  }
}
</script>
<style scoped>
@import '../../assets/css/index.css';
</style>

